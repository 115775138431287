import React             from 'react';
import { graphql }       from 'gatsby';
import { GiDiamondRing } from 'react-icons/gi';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import CallToAction  from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import Subnavigation from '@interness/web-core/src/components/structure/Subnavigation/Subnavigation';
import TwoColumn     from '@interness/theme-sonora/src/components/TwoColumn';

import { editionMenu } from '../../data/menus';

function LessmannEditionPage({ data }) {
  return (
    <>
      <Subnavigation menu={editionMenu}/>
      <Wrapper>
        <Heading icon={<GiDiamondRing/>}>Einzigartiger Schmuck - Die Lessmann Edition</Heading>
        <p>Jeder bedeutende Lebensmoment verdient ein einzigartiges Schmuckstück, das für die Ewigkeit geschaffen wurde.
          Unsere Kollektion wurde mit viel Sorgfalt entworfen, um für die besonderen Momente Ihres Lebens ein passendes,
          zeitloses Schmuckstück bereitzuhalten. Ob Sie nach dem perfekten Stück für eine Kommunion, den Schulabschluss,
          den 18. Geburtstag, die Hochzeit oder die Geburt Ihres Kindes suchen - die Lessmann Edition bietet Ihnen eine
          breite Palette an einzigartigen und individuell gestalteten Schmuckstücken, die speziell auf Ihre Bedürfnisse
          zugeschnitten sind.
        </p>
        <p>Mit unserer Leidenschaft für Handwerkskunst und Liebe zum Detail kreieren wir für Sie Schmuckstücke, die Sie
          ein Leben lang begleiten werden. Entdecken Sie die Lessmann Edition und lassen Sie uns gemeinsam Ihre
          besonderen Momente unvergesslich machen.</p>
        <Spacer/>
        <section>
          <Heading tag="h2" id="kommunion">Kommunion</Heading>
          <TwoColumn title="Das unvergängliche Geschenk für diesen besonderen Tag" image={data.lessmannedition.images[0].file.localFile.childImageSharp.fluid}>
            <p>Die Kommunion Ihres Kindes ist ein bedeutender
              Lebensabschnitt, der mit Liebe und Feierlichkeit begangen wird. Die Frage, was das perfekte Geschenk für
              diesen besonderen Anlass sein könnte, beschäftigt viele Eltern und Verwandte.</p>

            <p>Ein individuell angefertigtes Schmuckstück zur Kommunion ist weit mehr als nur ein Geschenk. Es ist ein
              Symbol und Erinnerung an diesen besonderen Tag, der für immer im Herzen Ihres Kindes bleiben wird.
              Lassen
              Sie sich in unserer kleinen Galerie inspirieren und sprechen Sie uns an.</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <section>
          <Heading tag="h2" id="geburtstag">18. Geburtstag</Heading>
          <TwoColumn inverted title="Feiern Sie die Volljährigkeit mit zeitlosem Glanz"
                     image={data.lessmannedition.images[4].file.localFile.childImageSharp.fluid}>
            <p>Jeder 18. Geburtstag markiert den Übergang in einen
              neuen Lebensabschnitt voller Möglichkeiten und Herausforderungen. Ein maßgefertigtes Schmuckstück kann
              zu
              einem treuen Begleiter werden, der an diesem bedeutenden Tag überreicht wird und die unvergesslichen
              Momente des Erwachsenwerdens symbolisiert.</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <section>
          <Heading tag="h2" id="hochzeit">Hochzeit</Heading>
          <TwoColumn title="Trauringe für eine einzigartige Reise"
                     image={data.lessmannedition.images[3].file.localFile.childImageSharp.fluid}>
            <p>Trauringe sind weit mehr als Schmuck – sie symbolisieren den Beginn
              eines gemeinsamen Lebenswegs, geprägt von Liebe, Familie und Verantwortung. Eure Liebe ist einzigartig,
              eure Geschichte ist einzigartig, und eure Trauringe sollten es auch sein. Entdeckt, wie individuell
              angefertigte Trauringe eure Liebe und euren neuen Lebensabschnitt perfekt widerspiegeln.</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <section>
          <Heading tag="h2" id="geburt">Geburt</Heading>
          <TwoColumn inverted title="Begrüßen Sie das Leben Ihres Kindes mit einem einzigartigen Statement"
                     image={data.lessmannedition.images[1].file.localFile.childImageSharp.fluid}>
            <p>Der Beginn einer Familie markiert
              einen bedeutsamen Wendepunkt im Leben, der tiefe Verantwortung und bedingungslose Liebe hervorruft. Als
              junger Vater steht Ihnen eine aufregende Reise bevor, die mit dem erstaunlichen Wunder der Geburt
              beginnt.
              Zeigen Sie Ihre bedingungslose Liebe und Ihr Engagement für Ihre Familie mit einem individuell
              angefertigten Ring, der den unvergesslichen Moment der Geburt für immer symbolisiert.</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <section>
          <Heading tag="h2" id="erinnerungen">Besondere Erinnerungen</Heading>
          <TwoColumn title="Zeitlose Erinnerung bewahren"
                     image={data.lessmannedition.images[2].file.localFile.childImageSharp.fluid}>
            <p>In einer Zeit des Abschieds kann die Umgestaltung von Erbstücken zu einem symbolträchtigen Schmuckstück
              eine bedeutsame Art sein, die Erinnerung an einen geliebten Menschen zu bewahren und weiterzuführen. Ein
              mit Bedacht gestaltetes Schmuckstück kann als ein einzigartiges Erbe dienen, das über Generationen
              hinweg weitergegeben wird.</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <CallToAction/>
      </Wrapper>
    </>
  )
}

export default LessmannEditionPage;

export const query = graphql`
    query {
        lessmannedition: directusMediaCollection(name: {eq: "lessmann-edition"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
