import React          from 'react';
import { graphql }    from 'gatsby';
import Img            from 'gatsby-image';
import { RiTeamFill } from 'react-icons/ri';
import { Flex, Box }  from '@chakra-ui/react';

import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import TeamMembers  from '@interness/web-core/src/components/modules/TeamMembers';

function AboutPage({ data }) {
  return (
    <>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading icon={<RiTeamFill/>}>Über uns</Heading>
          <Flex flexWrap="wrap">
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 16]}>
              <Box border="5px solid rgba(255,255,255,0.8)">
                <Img fluid={data.ueberuns.images[0].file.localFile.childImageSharp.fluid}/>
              </Box>
            </Box>
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 16]}>
              <p>Mein Name ist Patrick Lessmann, Goldschmiedemeister und Inhaber eines Juweliergeschäfts in
                Kaarst-Büttgen.</p>

              <p>Als Goldschmiedemeister habe ich mich darauf spezialisiert, Schmuckstücke zu gestalten, die für meine
                Kunden eine tiefergehende Bedeutung haben. Jedes meiner Werke ist ein Unikat, das die persönlichen
                Geschichten und Emotionen meiner Kunden widerspiegelt.</p>
              <Box maxW={'300px'}>
                <Img fluid={data.signatur.images[1].file.localFile.childImageSharp.fluid}/>
              </Box>
            </Box>
          </Flex>
        </section>
        <section>
          <h3>In meinem Geschäft steht der Mensch im Mittelpunkt.</h3>
          <p>Das ist mir einmal mehr in Bewusstsein gerückt, als wir begonnen haben, unseren Online-Auftritt zu
            überarbeiten. Ja, wir kreieren und verkaufen Schmuck - doch hinter jedem Schmuckstück stehen Emotionen, die
            damit ausgelöst werden - sei es für die Beschenkten, die Schenkenden oder auch für mich als
            Goldschmiedemeister. Schmuckstücke zu kreieren, bedeutet für mich nicht nur Edelmetalle und Edelsteine zu
            verarbeiten - wir schaffen Erinnerungen, die bleiben und manchmal sogar Generationen überdauern.</p>
          <h3>Die Designs meiner Schmuckstücke sprechen leise, aber eindrucksvoll.</h3>
          <p>Sie erzählen Geschichten von Handwerkskunst, Individualität und zurückhaltender Raffinesse. Jedes Detail
            wird mit größter Sorgfalt und Hingabe geschaffen, um ein einzigartiges Kunstwerk zu formen, das nicht nur
            getragen, sondern auch gelebt wird.</p>
        </section>
        <section>
          <h2>Mein Team</h2>
          <p>Wir glauben daran, dass Schmuck mehr ist als nur ein Accessoire – es ist ein Ausdruck der Persönlichkeit,
            des Stils und der Emotionen unserer Kunden. Durch unsere Arbeit möchten wir nicht nur Schönheit schaffen,
            sondern auch Herzen berühren.</p>
          <TeamMembers layout={'grid'}/>
        </section>
        <Spacer/>
        <CallToAction/>
      </Wrapper>
    </>
  )
}

export default AboutPage;

export const query = graphql`
    query {
        ueberuns: directusMediaCollection(name: {eq: "ueber-uns"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        signatur: directusMediaCollection(name: {eq: "signatur"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;