import React         from 'react';
import { graphql }   from 'gatsby';
import Img           from 'gatsby-image';
import { BsTools }   from 'react-icons/bs';
import { Flex, Box } from '@chakra-ui/react';

import HeaderImage  from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import TwoColumn    from '@interness/theme-sonora/src/components/TwoColumn';

function ServicePage({ data }) {
  return (
    <>
      <HeaderImage>
        <Img fluid={data.headerImage.media[0].file.localFile.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading icon={<BsTools/>}>Service</Heading>
          <TwoColumn image={data.service.images[2].file.localFile.childImageSharp.fluid}>
            <h3>Schmuck- & Edelstein-Gutachten</h3>
            <p>Bewertung von Schmuckstücken und Edelsteinen</p>
            <br/><br/>
            <h3>Individuelle Anfertigungen</h3>
            <p>Schmuckerstellung, Umarbeiten, Neuanfertigungen</p>
          </TwoColumn>
          <TwoColumn inverted image={data.service.images[1].file.localFile.childImageSharp.fluid}>
            <h3>Schmuck-Service</h3>
            <p>Goldschmiedereparaturen aller Art, Ringgrößen ändern, Perlenketten neu auffädeln und/oder knoten,
              Trauringumarbeitung, Schmuck reinigen und aufarbeiten</p>

            <h3>Gravuren</h3>
            <p>Gravuren in Ringe, Anhänger, Uhren, usw. Schützenketten, Schützenabzeichen</p>
          </TwoColumn>
          <TwoColumn image={data.service.images[0].file.localFile.childImageSharp.fluid}>
            <h3>Ohrlöcher stechen</h3>
            <p>Professionell, kompetent, einfühlsam</p>

            <h3>Uhren-Service</h3>
            <p>Batteriewechsel, Wasserdichtigkeitsprüfung, komplette Revision, Taschenuhren</p>
          </TwoColumn>
        </section>
        <Spacer/>
        <CallToAction/>
      </Wrapper>
    </>
  )
}

export default ServicePage;

export const query = graphql`
    query {
        headerImage: directusMediaCollection(name: {eq: "service-header"}) {
            name
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        service: directusMediaCollection(name: {eq: "service"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
