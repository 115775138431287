import React         from 'react';
import { graphql }   from 'gatsby';
import { BsTools }   from 'react-icons/bs';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import CallToAction  from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import Subnavigation from '@interness/web-core/src/components/structure/Subnavigation/Subnavigation';
import TwoColumn         from '@interness/theme-sonora/src/components/TwoColumn';

import { werkstattMenu } from '../../data/menus';

function WerkstattPage({ data }) {
  return (
    <>
      <Subnavigation menu={werkstattMenu}/>
      <Wrapper>
        <Heading icon={<BsTools/>}>Hinter den Kulissen der Goldschmiede Lessmann</Heading>
        <p>Die meisten unserer Kunden kennen unser Geschäft, wissen jedoch nicht, was sich alles hinter den Kulissen in
          unserer eigenen Goldschmiede-Werkstatt abspielt. Und ja - irgendwie liegt es auch in unserer Verantwortung,
          als Botschafter unseres Berufes zu fungieren und Ihnen Einblicke hinter die Kulissen zu gewähren.</p>

        <p>In der Welt der Goldschmiedekunst gibt es eine Symbiose zwischen traditionellen Handwerkspraktiken und
          innovativen Technologien. Unsere Werkstatt ist nicht nur ein Ort der Kreation, sie ist auch ein Ort an dem wir
          mit Hingabe, Verantwortung und Innovation einzigartige Schmuckstücke erschaffen.</p>
        <Spacer/>
        <section>
          <Heading tag="h2" id="werkstatt">Unsere Werkstatt</Heading>
          <TwoColumn image={data.werkstatt.images[3].file.localFile.childImageSharp.fluid}>
            <p>Entdecken Sie die Kunst des Handwerks. Unsere Werkstatt ist klein aber fein. Auch wenn wir keine Schauwerkstatt haben, möchten wir Ihnen
              Einblicke in unsere Arbeit als Goldschmiedemeister gewähren. Von der Konzeption bis zur Vollendung
              durchläuft jedes Schmuckstück einen Prozess, der von Meisterhand geführt wird. Dabei verbinden wir
              jahrhunderte- alte Techniken mit modernem Know- how, um einzigartige Schmuckstücke von höchster Qualität
              zu schaffen. Jedes Schmuckstück, das unsere Werkstatt verlässt, erzählt eine Geschichte – vielleicht
              auch Ihre Geschichte?</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <section>
          <Heading tag="h2" id="werkzeuge">Werkzeuge, die Meisterstücke formen</Heading>
          <TwoColumn inverted image={data.werkstatt.images[1].file.localFile.childImageSharp.fluid}>
            <p>Wie in anderen Handwerksberufen benötigen wir neben dem Material auch unser Werkzeug und unsere
              Maschinen, um einzigartige Schmuckstücke für unsere Kunden herzustellen.</p>

            <p>Jedoch ist bei uns das Werkzeug mitunter sehr viel kleiner. Unsere Werkzeuge sind quasi die
              Verlängerung unserer Hände. Auch wir arbeiten mit Zangen, Hammer, Säge, Feilen. Wir löten und schmieden
              und für einige Arbeiten kommen auch deutlich größere Maschinen zum Einsatz. Das sind zum Beispiel die
              Walze, die Presse oder die Ziehbank. Unterm Strich mag es manchmal auf der Arbeitsbank eines
              Goldschmieds etwas unordentlich auszusehen, doch das Genie beherrscht das Chaos. ;-)</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <section>
          <Heading tag="h2" id="gestaltung">3-D Gestaltung trifft traditionelles Handwerk</Heading>
          <TwoColumn image={data.werkstatt.images[0].file.localFile.childImageSharp.fluid}>
            <p>Wer an Goldschmiede denkt, hat meist eine Werkbank, Gold oder Edelsteine vor seinem geistigen Auge.
              Doch auch in unserem Handwerksberuf nutzen wir fortschrittliche Computertechnologien. Die 3-D-
              Gestaltung am Computer gibt uns noch einmal mehr die Freiheit und Kreativität, für unsere Kunden ganz
              individuelle Schmuckstücke entstehen zu lassen.</p>

            <p>Sie haben eine Idee und gemeinsam entwickeln wir mit Ihnen in enger Korrespondenz Ihr perfektes
              Schmuckstück.</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <section>
          <Heading tag="h2" id="material-und-edelsteine">Material & Edelsteine</Heading>
          <TwoColumn inverted image={data.werkstatt.images[2].file.localFile.childImageSharp.fluid}>
            <p>Wir legen wir großen Wert darauf, Schönheit und Verantwortung miteinander zu verbinden. Aus diesem
              Grund arbeiten wir daran, nachhaltige Materialien und Edelsteine in unserer Handwerkskunst zu verwenden,
              um einzigartige Schmuckstücke zu schaffen. Die Verwendung von recyceltem Gold ist für uns ein wichtiger
              Schritt in Richtung Nachhaltigkeit. Dadurch reduzieren wir den Bedarf an neu abgebautem Gold. Dieser
              Ansatz trägt dazu bei, die ökologischen Fußabdrücke unserer Schmuckstücke zu minimieren Wir bevorzugen
              zertifizierte Edelsteine, die nicht nur eine Garantie für ihre Qualität, sondern auch für ihre Herkunft
              bieten.</p>

            <p>Wir nutzen kreative Upcycling-Techniken. Mehr dazu erfahren Sie unter unserer Rubrik Umarbeiten und
              Recycling</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <section>
          <Heading tag="h2" id="botschafter">Botschafter des Goldschmiedehandwerks</Heading>
          <TwoColumn image={data.werkstatt.images[4].file.localFile.childImageSharp.fluid}>
            <p>Als Inhaber und Goldschmiedemeister ist es mir ein persönliches Anliegen, als Botschafter für unseren
              Handwerkszweig zu fungieren.</p>

            <p>Unser Berufsfeld ist vielfältig und erstreckt sich über viele Bereiche vom künstlerisch- kreativen,
              über den handwerklichen und digitalen Arbeitsbereich. Unsere Werkstatt ist mehr als nur ein Ort des
              Schaffens– sie ist eine Botschaft, die die Werte des Goldschmiedehandwerks verkörpert.</p>

            <p>Durch die Präsentation von einzigartigen, handgefertigten Schmuckstücken schaffen wir nicht nur
              ästhetische Meisterwerke, sondern demonstrieren gleichzeitig die hohe Qualität und das handwerkliche
              Können für unseren Berufszweig und fördern aktiv den Austausch von Wissen und Fähigkeiten.</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <CallToAction/>
      </Wrapper>
    </>
  )
}

export default WerkstattPage;

export const query = graphql`
    query {
        werkstatt: directusMediaCollection(name: {eq: "werkstatt"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
