import React                                        from 'react';
import { graphql, Link }                            from 'gatsby';
import { GiDiamondRing, GiShop }                    from 'react-icons/gi';
import { useMediaQuery, Button, Center } from '@chakra-ui/react'
import Img                                          from 'gatsby-image';

import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import CallToAction      from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import BrandsDisplay     from '@interness/theme-sonora/src/components/Display/BrandsDisplay';
import SEO               from '@interness/web-core/src/components/modules/SEO/SEO';
import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';
import Display           from '@interness/theme-sonora/src/components/Display/Display';
import RandomShopItems   from '@interness/ecommerce-addon/src/components/RandomShopItems/RandomShopItems';
import TwoColumn         from '@interness/theme-sonora/src/components/TwoColumn';

function IndexPage({ data }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const serviceItems = [
    {
      title: 'Werkstatt',
      image: data.services.images[1].file.localFile,
      link: '/werkstatt',
    },
    {
      title: 'Lessmann Edition',
      image: data.services.images[0].file.localFile,
      link: '/lessmann-edition',
    },
    {
      title: 'Recycling',
      image: data.services.images[2].file.localFile,
      link: '/recycling',
    }
  ];
  return (
    <>
      <SEO title={'Startseite'}/>
      <Carousel>
        {data[isMobile ? 'headerImagesMobile' : 'headerImages'].images.map((image) => (
          <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <LiveAnnouncements/>
        <Spacer/>
        <section>
          <Heading subtitle={'Ihr Juwelier und Goldschmiedemeister im Herzen von Büttgen'} icon={<GiDiamondRing/>}>Juwelier Lessmann</Heading>
          <Spacer />
          <TwoColumn inverted title="Schmuck der berührt" image={data.patrick.images[0].file.localFile.childImageSharp.fluid}>
            <p>Als Goldschmiedemeister habe ich mich darauf spezialisiert, Schmuckstücke zu gestalten, die für meine
              Kunden eine tiefergehende Bedeutung haben. Ihre persönlichen Geschichten und Emotionen stehen für mich
              im Mittelpunkt. Gemeinsam mit Ihnen erschaffen wir Schmuckstücke, die weit über das Materielle hinausgehen
              und bleibende Erinnerungen schaffen.</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <section>
          <Heading tag={'h2'} icon={<GiShop/>}>Was wir anbieten</Heading>
          <p>Unsere langjährige Erfahrung im Juwelierhandwerk hat uns gelehrt, dass wahre Schönheit in den Details
            liegt. In unserem Juweliergeschäft finden Sie nicht nur eine exquisite Auswahl an fertigen Schmuckstücken
            und Uhren, sondern auch unsere eigene Meister-Werkstatt.</p>
          <p>Was uns auszeichnet, ist nicht nur unser handwerkliches Können, sondern auch unsere Fähigkeit, Ihre Wünsche
            zu verstehen und umzusetzen.</p>
          <p>Unsere individuelle Beratung basiert auf einem tiefen Verständnis für Ästhetik und Stil. Wir nehmen uns die
            Zeit, um Ihre Vorstellungen zu erfassen und Ihnen dabei zu helfen, das perfekte Schmuckstück auszuwählen
            oder zu gestalten– sei es für besondere Anlässe oder den Alltag.</p>
          <Spacer height={40}/>
          <Display colCount={3} items={serviceItems}/>
          <BrandsDisplay/>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<GiDiamondRing/>}>Ausgewählte Schmuckstücke</Heading>
          <RandomShopItems/>
          <Spacer/>
          <Center>
            <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} to="/schaufenster">Mehr Produkte
              entdecken</Button>
          </Center>
        </section>
        <Spacer/>
        <CallToAction/>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "home-header"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        headerImagesMobile: directusMediaCollection(name: {eq: "home-header-mobile"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        patrick: directusMediaCollection(name: {eq: "home"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        services: directusMediaCollection(name: {eq: "home-nav"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 500, maxHeight: 500, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
