import React         from 'react';
import { graphql }   from 'gatsby';
import { FaRecycle } from 'react-icons/fa';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import CallToAction  from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import TwoColumn     from '@interness/theme-sonora/src/components/TwoColumn';

function RecyclingPage({ data }) {
  return (
    <>
      <Wrapper>
        <Spacer />
        <Heading icon={<FaRecycle/>} subtitle={'Schönheit und Nachhaltigkeit Hand in Hand'}>Umarbeiten /
          Recycling</Heading>
        <p>Schmuckrecycling spielt in unserer Goldschmiede eine große Rolle, die wir sehr ernst nehmen. Es ist eine
          Verantwortung, die der Vergangenheit und der Zukunft gleichermaßen gerecht werden muss. Kunden, die mit
          geerbten
          Schmuckstücken zu uns kommen, stehen nicht selten vor den Frage, was sie damit machen sollen: verkaufen oder
          umarbeiten. Hinzu kommt, dass an diesem Schmuck ein emotionaler Wert hängt.</p>

        <p>Andererseits ist alter Schmuck - sofern er nicht mehr getragen und benötigt wird - eine wertvolle Ressource
          für uns als Goldschmiede. Durch
          Recycling bestehender Materialien gehen wir auch in die Verantwortung für zukünftige Generationen, diese
          kostbaren Ressourcen zu schonen.</p>
        <Spacer/>
        <section>
          <Heading tag="h2">Schmuckannahme und Bewertung</Heading>
          <TwoColumn image={data.recycling.images[1].file.localFile.childImageSharp.fluid}>
            <p>Bringen Sie uns Ihren alten Schmuck, und wir bewerten ihn sorgfältig. Wir prüfen die Qualität der
              Edelmetalle und Steine und geben Ihnen eine transparente Einschätzung des Wertes.</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <section>
          <Heading tag="h2">Professionelle Aufarbeitung</Heading>
          <TwoColumn inverted image={data.recycling.images[2].file.localFile.childImageSharp.fluid}>
            <p>Wir als Goldschmiedemeister sind Meister unseres Fachs. Um den Schmuck seinen ursprünglichen Glanz
              wieder zu verleihen, führen wir eine sorgfältige Reinigung und Reparatur durch.</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <section>
          <Heading tag="h2">Kreatives Redesign</Heading>
          <TwoColumn image={data.recycling.images[0].file.localFile.childImageSharp.fluid}>
            <p>Sie haben ein altes Schmuckstück von emotionalem Wert, das Sie jedoch selbst nicht tragen würden? Mit
              unserer Kreativität und Feingefühl verleihen wir Ihrem Schmuck durch Redesign ein zeitgemäßes Aussehen.
              Hierzu stehen uns vielfältige Möglichkeiten offen, den Schmuck so zu gestalten, dass er perfekt zu Ihrem
              Stil passt und auch Erinnerungen an einen geliebten Menschen bewahrt.</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <section>
          <Heading tag="h2">Unsere Verantwortung für zukünftige Generationen</Heading>
          <TwoColumn inverted image={data.recycling.images[3].file.localFile.childImageSharp.fluid}>
            <p>Auch in unserem Handwerksbereich gewinnt Recycling zunehmend an Bedeutung, da es einen wichtigen Beitrag
              zur Nachhaltigkeit und Ressourcenschonung leistet. Durch die Verwendung von recyceltem Gold oder anderen
              Edelmetallen gehen wir einen Schritt in Richtung nachhaltiger Schmuckherstellung. Es eröffnet uns nicht
              nur die Möglichkeit, individuelle Designs und Unikate für unsere Kunden zu erschaffen, sondern wir haben
              dadurch auch die Möglichkeit unsere Kunden für einen nachhaltigen Konsum auch in unserer Branche zu
              sensibilisieren.</p>
          </TwoColumn>
          <Spacer/>
        </section>
        <CallToAction/>
      </Wrapper>
    </>
  )
}

export default RecyclingPage;

export const query = graphql`
    query {
        recycling: directusMediaCollection(name: {eq: "umarbeitung"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;